import {request} from "../request";

// 下载练习软件
export function downloadZip(data) {
    return request({
        method: "post",
        url: "/buaa/download/downloadZip",
        data,
    });
}

// 下载pdf
export function downloadPdf(data) {
    return request({
        method: "post",
        url: "/buaa/download/downloadPdf",
        data,
    });
}

// 获取pdf页面数据
export function getPdfInfo(data) {
    return request({
        method: "post",
        url: "/buaa/download/getPdfInfo",
        data,
    });
}

// 获取pdf页面数据-无需登录
export function getPdfInfoNoLogin(data) {
    return request({
        method: "post",
        url: "/buaa/download/getPdfInfoNoLogin",
        data,
    });
}
