<template>
    <div id="pdf-body">
        <div class="pdf_area">
            <pdf-area type="2"/>
        </div>
    </div>
</template>

<script>
    import PdfArea from "c/index/PdfArea";

    export default {
        name: "pdf",
        components: {PdfArea},
    }
</script>

<style scoped lang="scss">
    #pdf-body {
        position: relative;
        margin: 0;
        color: #000000;
        font-family: 华文仿宋;
        background-color: #000000;

        .pdf_area {
            /*width: 754px;*/
            /*height: 1082px;*/
            /*padding: 20px;*/
            width: 714px;
            height: 1042px;
            padding: 40px;
            margin: 0 auto;
            background: #fff url('../../assets/images/shuiyin.png') repeat 0 0;
        }

    }
</style>
